import React, { useState } from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import MainLogo from 'assets/logo.svg';
import LangSwitcher from 'components/LangSwitcher/LangSwitcher';
import * as Styled from './NavigationStyles';

const Navigation: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  return (
    <Styled.HeaderWrapper>
      <Styled.NavWrapper>
        <Styled.Logo as={Link} to="/" className="logo" aria-label="logo">
          <MainLogo />
        </Styled.Logo>
        <Styled.NavWrapperRight>
          <LangSwitcher />
          <Styled.MenuWrapper>
            <Styled.MenuList animate={isVisible ? 'visible' : 'hidden'}>
              <Styled.NavItemList>
                <Link to="/books" aria-label={formatMessage({ id: 'books.title' })}>
                  {formatMessage({ id: 'books.title' })}
                </Link>
              </Styled.NavItemList>
              <Styled.NavItemList>
                <Link to="/media" aria-label={formatMessage({ id: 'media.title' })}>
                  {formatMessage({ id: 'media.title' })}
                </Link>
              </Styled.NavItemList>
              <Styled.NavItemList>
                <Link to="/about" aria-label={formatMessage({ id: 'about.title' })}>
                  {formatMessage({ id: 'about.title' })}
                </Link>
              </Styled.NavItemList>
              <Styled.NavItemList>
                <Link to="/contact" aria-label={formatMessage({ id: 'contact.title' })}>
                  {formatMessage({ id: 'contact.title' })}
                </Link>
              </Styled.NavItemList>
            </Styled.MenuList>
            <Styled.HamburgerWrapper expand={isVisible} onClick={() => setIsVisible(state => !state)}>
              <Styled.Hamburger />
            </Styled.HamburgerWrapper>
          </Styled.MenuWrapper>
        </Styled.NavWrapperRight>
      </Styled.NavWrapper>
    </Styled.HeaderWrapper>
  );
};

export default Navigation;
