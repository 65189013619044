import styled, { css } from 'styled-components';
import { Font, Colors, Media } from 'config/CONSTANTS';
import { rem } from 'polished';

type ButtonProps = {
  icon?: boolean | string;
  link?: boolean | string;
  secondary?: boolean | string;
};

type ButtonsContainerProps = {
  space?: boolean;
  tabletBlock?: boolean;
};

export const ButtonLink = styled.a``;

export const ButtonsWrapper = styled.div`
  margin-top: 40px;
`;

export const Button = styled.button`
  position: relative;
  background-color: ${Colors.PRIMARY};
  border: none;
  border-radius: 28px;
  padding: 16px 25px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  outline: none;
  text-decoration: none;

  &::before {
    content: none;
  }

  @media (min-width: ${Media.MOBILE}) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${Colors.DARK};
      opacity: 0;
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    &:hover {
      &::before {
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }

  ${({ secondary }: ButtonProps) =>
    secondary &&
    css`
      background-color: transparent;
      border: 1px solid ${Colors.PRIMARY};

      &::before {
        background-color: ${Colors.PRIMARY};
      }

      span {
        color: ${Colors.BLACK};
        transition: color 0.3s ease, transform 0.3s ease;
      }

      .arrow--icon {
        svg path {
          stroke: ${Colors.PRIMARY};
          transition: stroke 0.3s ease;
        }
      }

      &:hover {
        span {
          color: ${Colors.LIGHT};
        }

        .arrow--icon {
          svg path {
            stroke: ${Colors.LIGHT};
          }
        }
      }
    `}

  ${({ icon }: ButtonProps) =>
    icon &&
    css`
      padding: 16px 120px 16px 25px;

      &:hover {
        .arrow--icon {
          transform: translateY(-50%) rotate(-90deg);
        }
      }
    `}

  ${({ link }: ButtonProps) =>
    link &&
    css`
      background-color: transparent;
      text-decoration: none;
      border-radius: 0;
      padding: 12px 0;
      width: auto;
      
      .gatsby-image-wrapper {
        min-width: 40px;
      }

      @media (min-width: ${Media.TABLET}) {
        padding: 0;
      }

      &::before {
        height: 1px;
        top: auto;
        bottom: 0;
      }

      @media (min-width: ${Media.MOBILE}) {
        &:not(:first-child) {
          margin-left: 30px;
        }
      }

      span {
        font-size: ${rem('16px')};
        font-weight: ${Font.WEIGHTREGULAR};
        color: ${Colors.BLACK};
        transition: color 0.3s ease;
      }

      &:hover {
        span {
          color: ${Colors.DARK};
        }
      }
    `}

    @media (min-width: 500px) {
    width: auto;
    max-width: 250px;
  }
`;

export const ButtonText = styled.span`
  font-family: ${Font.FAMILY};
  font-size: ${rem('14px')};
  font-weight: ${Font.WEIGHTSEMIBOLD};
  color: ${Colors.LIGHT};
  position: relative;
  z-index: 99;
`;

export const ButtonIconArrow = styled.span`
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  ${({ space }: ButtonsContainerProps) =>
    space &&
    css`
      a {
        &:not(:first-child) {
          margin-top: 15px;
        }
      }
    `};

  @media (min-width: ${Media.MOBILE}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    ${({ space }: ButtonsContainerProps) =>
      space &&
      css`
        a {
          &:not(:first-child) {
            margin-left: 15px;
            margin-top: 0;
          }
        }
      `}
  }

  @media (max-width: ${Media.TABLET}) {
    margin-top: 50px;

    ${({ tabletBlock }: ButtonsContainerProps) => tabletBlock && css`
      flex-direction: column;
      align-items: flex-start;
      a {
        &:not(:first-child) {
          margin-left: 0;
          margin-top: 15px;
        }
      }
      `}
  }
`;
