export enum Colors {
  PRIMARY = '#14171C',
  DARK = '#474C55',
  BLACK = '#000000',
  LIGHT = '#ffffff',
  GRAY = '#cccccc',
}

export enum Font {
  FAMILY = 'Poppins, sans-serif',
  QUOTES = 'Gelasio, serif',
  WEIGHTREGULAR = '400',
  WEIGHTMEDIUM = '500',
  WEIGHTSEMIBOLD = '600',
  WEIGHTBOLD = '700',
}

export enum Media {
  DESKTOP = '1024px',
  TABLET = '768px',
  MOBILE = '576px',
}
