import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Font, Colors, Media } from 'config/CONSTANTS';
import { rem } from 'polished';

type QuoteContentProps = {
  notAnimate?: boolean;
};

const quoteVisibleVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, delayChilden: 0.2, staggerChildren: 0.1 },
  },
  hidden: {
    y: 100,
    opacity: 0,
  },
};

export const QuoteWrapper = styled.div`
  position: relative;
  display: none;
  width: 60%;
  height: 100%;
  padding-left: 0;
  z-index: 99;

  svg {
    width: 40px;

    @media (min-width: ${Media.DESKTOP}) {
      width: 60px;
    }
  }

  @media (min-width: ${Media.TABLET}) {
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  @media (min-width: 1440px) {
    padding-left: 0;
  }
`;

export const QuoteContent = styled(motion.span).attrs(() => ({
  initial: 'hidden',
  variants: quoteVisibleVariants,
}))`
  font-family: ${Font.QUOTES};
  font-size: ${rem('20px')};
  line-height: ${rem('32px')};
  font-style: italic;
  color: ${Colors.LIGHT};
  padding-left: 85px;
  margin-top: -6px;
  position: absolute;

  @media (min-width: ${Media.DESKTOP}) {
    font-size: ${rem('20px')};
    line-height: ${rem('32px')};
  }

  @media (min-width: 1440px) {
    font-size: ${rem('25px')};
    line-height: ${rem('40px')};
  }

  ${({ notAnimate }: QuoteContentProps) => notAnimate && `opacity: 1!important; transform: none!important`}
`;
