import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Font, Colors, Media } from 'config/CONSTANTS';

type LangButtonProps = {
  selected: boolean;
};

export const LangWrapper = styled.div`
  display: none;

  @media (min-width: ${Media.TABLET}) {
    display: flex;
    align-items: center;
  }
`;

export const LangButton = styled.span`
  font-size: ${rem('14px')};
  font-weight: ${Font.WEIGHTREGULAR};
  color: ${Colors.LIGHT};
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.3s ease-in;
  ${({ selected }: LangButtonProps) =>
    selected &&
    css`
      font-weight: ${Font.WEIGHTSEMIBOLD};
      color: ${Colors.LIGHT};

      @media (min-width: ${Media.DESKTOP}) {
        color: ${Colors.BLACK};
      }
    `};

  &:hover {
    color: ${Colors.BLACK};
  }

  @media (min-width: ${Media.DESKTOP}) {
    color: ${Colors.DARK};

    &:hover {
      color: ${Colors.BLACK};
    }
  }
`;

export const Slash = styled.span`
  font-size: ${rem('14px')};
  font-weight: ${Font.WEIGHTREGULAR};
  color: ${Colors.DARK};
  pointer-events: none;
  margin: 0 8px;
`;
