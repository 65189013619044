import React from 'react';
import { useIntl, changeLocale } from 'gatsby-plugin-intl';
import * as Styled from './LangSwitcherStyles';

const LangSwitcher: React.FC = () => {
  const { locale } = useIntl();

  const handleChangeLang = (locale: string) => {
    changeLocale(locale);
  };

  return (
    <Styled.LangWrapper>
      <Styled.LangButton selected={locale === 'pl'} onClick={() => handleChangeLang('pl')}>
        pl
      </Styled.LangButton>
      <Styled.Slash>/</Styled.Slash>
      <Styled.LangButton selected={locale === 'en'} onClick={() => handleChangeLang('en')}>
        eng
      </Styled.LangButton>
    </Styled.LangWrapper>
  );
};

export default LangSwitcher;
