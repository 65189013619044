import React from 'react';
import FacebookIcon from 'assets/facebook.svg';
import BooksIcon from 'assets/books.svg';
import * as Styled from './SocialMediaStyles';

type SocialProps = {
  social: Social;
};

type Social = {
  fb: string;
  book: string;
};

const SocialMedia: React.FC<SocialProps> = ({ social }) => {
  const { fb, book } = social;

  return (
    <Styled.SocialMediaWrapper>
      <Styled.SocialMediaItem href={`${fb}`} target="_blank" aria-label="facebook">
        <FacebookIcon />
      </Styled.SocialMediaItem>
      <Styled.SocialMediaItem href={`${book}`} target="_blank" aria-label="lubimy czytać">
        <BooksIcon />
      </Styled.SocialMediaItem>
    </Styled.SocialMediaWrapper>
  );
};

export default SocialMedia;
