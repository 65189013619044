import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { rem } from 'polished';
import { Font, Colors, Media } from 'config/CONSTANTS';

type HamburgerWrapperProps = {
  expand: boolean;
};

const listVariants = {
  visible: {
    opacity: 1,
    pointerEvents: 'all',
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
    transition: {
      when: 'afterChildren',
    },
  },
};

const itemVariants = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: 100 },
};

export const HeaderWrapper = styled.header`
  padding: 15px 20px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  @media (min-width: ${Media.DESKTOP}) {
    padding: 36px 34px;
  }
`;

export const NavWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

export const NavWrapperRight = styled.div`
  @media (min-width: ${Media.TABLET}) {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuList = styled(motion.ul).attrs(() => ({
  initial: 'hidden',
  variants: listVariants,
}))`
  margin: 0;
  list-style: none;
  overflow: hidden;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  max-width: 250px;
  background-color: ${Colors.LIGHT};
  padding: 25px 0;
  box-shadow: 30px 30px 80px 0 rgba(20, 23, 28, 0.2);
  border-radius: 5px;

  @media (min-width: ${Media.DESKTOP}) {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    max-width: none;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
  }
`;

export const NavItemList = styled(motion.li).attrs(() => ({
  initial: 'hidden',
  variants: itemVariants,
}))`
  padding: 13px 24px;

  @media (min-width: ${Media.DESKTOP}) {
    display: inline-block;
    padding: 0 15px;
  }

  @media (min-width: 1440px) {
    padding: 0 24px;
  }

  a {
    font-size: ${rem('17px')};
    font-weight: ${Font.WEIGHTREGULAR};
    display: block;
    color: ${Colors.PRIMARY};
    text-decoration: none;
    padding-bottom: 2px;
    position: relative;
    opacity: 1;
    transition: color 0.3s ease-in;

    @media (min-width: ${Media.DESKTOP}) {
      font-size: ${rem('14px')};
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: left;
      opacity: 0;
      width: 100%;
      height: 1px;
      background-color: ${Colors.DARK};
      transition: transform 0.3s ease-in, opacity 0.3s ease-in;
    }

    &:hover {
      color: ${Colors.DARK};
      &::before {
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }
`;

export const Logo = styled.a`
  display: inline-flex;
  max-width: 150px;

  @media (min-width: ${Media.TABLET}) {
    max-width: 250px;
  }

  @media (min-width: ${Media.DESKTOP}) {
    display: block;
    max-width: none;
  }
`;

export const HamburgerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  position: relative;
  cursor: pointer;
  margin-left: 30px;

  &:hover div {
    &::after {
      transform: scaleX(1);
    }
  }

  ${({ expand }: HamburgerWrapperProps) =>
    expand &&
    css`
      div {
        &::after {
          transform: scaleX(1);
        }
      }
    `}
`;

export const Hamburger = styled.div`
  position: relative;
  width: 36px;
  height: 17px;

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    display: block;
    background-color: ${Colors.LIGHT};
    transform-origin: left;
    transition: transform 0.3s ease;

    @media (min-width: ${Media.DESKTOP}) {
      background-color: ${Colors.PRIMARY};
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
    transform: scaleX(0.8);
  }
`;
