import React from 'react';
import * as Styled from './HeroImageStyles';

const HeroImage: React.FC = ({ children }) => {
  return (
    <Styled.HeroSection>
      {children}
      <Styled.HeroSectionOverlay />
    </Styled.HeroSection>
  );
};

export default HeroImage;
