import { graphql, useStaticQuery } from 'gatsby';

export const useFooterQuery = () => {
  const { wordpressAcfPages } = useStaticQuery(
    graphql`
      query FooterQuery {
        wordpressAcfPages(wordpress_id: { eq: 63 }) {
          acf {
            fb
            book
          }
        }
      }
    `,
  );
  return wordpressAcfPages.acf;
};
