import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Colors, Media } from 'config/CONSTANTS';

type ContentProps = {
  top?: boolean;
  space?: boolean;
};

const visibleVariants = {
  visible: {
    y: 0,
    opacity: 1,
    pointerEvents: 'all',
    transition: { duration: 0.5, delayChilden: 0.2, staggerChildren: 0.1 },
  },
  hidden: {
    y: 100,
    opacity: 0,
    pointerEvents: 'none',
  },
};

export const ContentWrapper = styled.section`
  position: relative;
  padding: 20px;
  margin: auto 0 auto 0;

  @media (min-width: ${Media.TABLET}) {
    padding: 50px;

    ${({ space }: ContentProps) =>
      space &&
      css`
        padding: 130px 50px 50px;
      `}
  }

  @media (min-width: ${Media.DESKTOP}) {
    padding: 6rem 34px 0 0;
  }
`;

export const ContentRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -100px;

  @media (min-width: ${Media.TABLET}) {
    flex-direction: row;
    transform: none;
    margin: 0;

    ${({ top }: ContentProps) =>
      top &&
      css`
        flex-direction: column;
        padding-left: 5vw;
      `}
  }

  @media (min-width: ${Media.DESKTOP}) {
    position: relative;
  }

  ${({ top }: ContentProps) =>
    top &&
    css`
      align-items: flex-start;
      flex-direction: column;

      margin-top: 0;
    `}
`;

export const ContentInner = styled.div`
  height: 60vh;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  margin-top: 50px;
  
  @media (min-width: 320px) and (max-width: 767px) {
    min-height: 700px;
  }

  @media (min-width: ${Media.MOBILE}) {
    height: 40vh;
  }

  @media (min-width: ${Media.TABLET}) {
    margin-top: 0;
    display: flex;
    align-items: center;
  }

  @media (min-width: ${Media.DESKTOP}) {
    overflow-y: scroll;
    height: 60vh;

    &::-webkit-scrollbar {
      width: 1px !important;
      background: ${Colors.PRIMARY};
      height: 30px;
      display: block;
    }

    &::-webkit-scrollbar-track {
      background-color: ${Colors.GRAY};
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${Colors.PRIMARY};
      height: 20px;
      width: 3px;
    }
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 1440px) {
    margin-top: 2rem;
  }
`;

export const ContentWrapperContainer = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants: visibleVariants,
}))`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 25px;

  @media (min-width: ${Media.TABLET}) {
    padding-right: 50px;
    padding-left: 330px;
  }

  @media (min-width: ${Media.DESKTOP}) {
    max-width: 70%;
    left: auto;
    right: auto;
    padding: 0;
    margin-top: 0;
  }
`;
