import styled, { keyframes } from 'styled-components';
import { Media } from 'config/CONSTANTS';

const mouseAnimationMoved = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0.5;
    transform: translateY(8px);
  }
`;

export const FooterWrapper = styled.footer`
  width: 100vw;
  padding: 0 25px;

  @media (min-width: ${Media.TABLET}) {
    padding: 0 34px;
  }

  @media (min-width: ${Media.TABLET}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

export const MouseButton = styled.button`
  display: none;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  svg {
    animation: 1s ${mouseAnimationMoved} infinite alternate;
  }

  @media (min-width: ${Media.DESKTOP}) {
    display: block;
  }
`;

export const FooterWrapperItem = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin: 0px 8px;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }

  @media (min-width: ${Media.TABLET}) {
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`;
