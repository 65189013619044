import styled from 'styled-components';
import { Colors } from 'config/CONSTANTS';

export const SocialMediaWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

export const SocialMediaItem = styled.a`
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid ${Colors.PRIMARY};
    border-radius: 50%;
    transform: scale(0);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  &:not(:first-child) {
    margin-left: 15px;
  }

  &:hover {
    &::before {
      transform: scale(1);
      opacity: 1;
    }

    svg {
      transform: scale(0.7);
      opacity: 0.7;
    }
  }
`;
