import styled from 'styled-components';
import { Font, Colors, Media } from 'config/CONSTANTS';
import { rem } from 'polished';

export const SectionHeading = styled.h3`
  font-family: ${Font.QUOTES};
  font-size: ${rem('30px')};
  font-weight: ${Font.WEIGHTBOLD};
  color: ${Colors.PRIMARY};
  margin: 0;

  @media (min-width: ${Media.DESKTOP}) {
    font-size: ${rem('30px')};
  }

  @media (min-width: 1440px) {
    font-size: ${rem('50px')};
  }
`;
