import styled from 'styled-components';
import { Media } from 'config/CONSTANTS';

export const HeroSection = styled.section`
  position: relative;
  height: 35vh;
  border-bottom-right-radius: 110px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${Media.TABLET}) {
    height: 45vh;
  }

  @media (min-width: ${Media.DESKTOP}) {
    height: calc(100vh - 8rem);
    justify-content: flex-start;
  }

  @media (min-width: 1440px) {
    justify-content: center;
  }
`;

export const HeroSectionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
`;

export const HeroSectionImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper {
    height: 100%;
  }
`;
