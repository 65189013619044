import styled from 'styled-components';
import { Font, Colors } from 'config/CONSTANTS';
import { rem } from 'polished';

export const SectionParagraph = styled.p`
  font-size: ${rem('14px')};
  font-weight: ${Font.WEIGHTREGULAR};
  color: ${Colors.DARK};
  margin: 0;
`;
